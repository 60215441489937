@media only screen and (max-width: 600px) {
    .movilgo{
        width: 39%!important;
        margin-left: 30%!important;
        margin-top: 3%!important;
    }
    .icono1{
        width: 15% !important;
        margin-top: 1%;
        margin-left: -7%;
    }    
    
    .text1{
        font-size: 95%!important;
        margin-top: 6%;
    }

    .icono2{
        margin-left: -38%;
        width: 83%;
        margin-top: 3%;
    }

    .text2{
        font-size: 100%!important;
        margin-top: 3%;
        margin-left: -5% !important;
        margin-bottom: 8%;
    }

    .icono3{
        width: 96%!important;
        margin-left: -48%!important;
        margin-top: 1%!important;
    }

    .text3{
        font-size: 97%!important;
        margin-left: -38%!important;
        margin-top: 2%!important;
    }

    .icono4{
        width: 66%;
        margin-left: 40%;
    }

    .text4{
        font-size: 75%!important;
        margin-top: 2%;
        margin-left: 7%!important;
    }
    

    .icono5{
        width: 68%;
        margin-left: 268%;
        margin-top: -160% !important;
    }

    .text5{
        font-size: 75%!important;
        margin-top: -9.4%;
        margin-left: 35%!important;
    }

    .icono6{
        margin-left: 357%;
        margin-top: -88%;
        zoom: 57%;
    }

    .text6{       
        font-size: 83%!important;
        margin-top: -82%!important;
        margin-left: 34%!important;

    }


    .footer{
        width: 100%!important;
    }
      }