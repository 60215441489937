.fondo{
    border-color: transparent!important;
}
@media only screen and (max-width: 600px) {
    
    
 
.modal-content{
    width: 98%!important;
    margin-top: 26%;
}

.fondo{
    margin-left: 570%!important;
    margin-top: -43%!important;
}
    
     .principal{
        width: 166%;
    }
    .imagen{
        height: 19%;
        margin-top: 110% !important;
        margin-left: -32%;
        
    
    }
    
    .carrusel{
        width: 103%;
        zoom: 50%;
        margin-left: 37%;
        margin-top: 9% !important;
    }
    
.fondo1{

    height: 0% !important;
    
}

.fondo4{
    background-image: url(../../assets/home/app.png) !important;
    display: list-item;
    zoom: 100%;
    padding-top: 48%;
    background-size: 100%;
    margin-top: -24%;
}

.texto1{
    zoom: 33%;
}

.texto2{
    zoom: 39%;
    margin-left: -8%;
}
.texto3{
    zoom: 102%;
    margin-right: -13%;
}
.linea{
    margin-left: -30%;
    margin-top: 4% !important;
}

.linea2{
    width: 107%!important;
    margin-left: -9%;
    margin-top: 1%!important;
}
.sencillo{
    font-size: 199% !important;
}
.mujer{
    margin-left: 1% !important;
    width: 60%;
    margin-top: -6% !important;
}

/* .texto-app{
    margin-left: -156%;
    padding-bottom: 56%;
} */

.app{
    font-size: 133%!important;
    margin-left: -162%;
    font-weight: 700;

}

.ingresa{
    font-size: 109%!important;
    margin-left: -170%;
    margin-top: -25%!important;
}
.orientation{
    width: 254%;
}

.chulo{
    width: 21% !important;
    margin-left: -9%;
}

.chulo2{
    width: 13% !important;
    margin-left: 0%;
    margin-top: 2%!important;
}
.chulo3{
    width: 13% !important;
    margin-left: 0%;
    margin-top: 1%!important;
}

.corto1{
    margin-left: 9%;
    margin-top: -13%!important;
    font-size: 77%!important;
}

.corto2{
    margin-left: 17%;
    margin-top: -9%!important;
    font-size: 77%!important;
}
.corto3{
    
    margin-left: 17%;
    margin-top: -10%!important;
    font-size: 77%!important;
}
.linea3{
    width: 107%!important;
    margin-left: -9%;
    margin-top: 4%!important;
}

.cardoso1{
    margin-left: -5% !important;
    zoom: 40%;
    margin-top: -7%!important;

}

      }