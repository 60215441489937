.menu{
    float: right;
    background: transparent;
    border: none;
    font-size: 25px;
    margin-top: -56%;
    margin-left: -14%;
}


.menu{}

.verde_1{
    margin: auto;
    display: block;
    width: auto;
    background-color: rgb(13, 96, 110);
    border-radius: 10px;
    margin-top: 0%;
    padding-bottom: 0%;
    padding-top: 4%; 
}
.cardoso{
    float: left;
    margin-left: 48%;
    width: 90%;
    margin-top: -7.5%;
}


.cerrar{
    
    float: right;
    margin-top: 4%;
    margin-right: 1%;
 
}
.text-info {
    --bs-text-opacity: 1;
    color: white 1!important;
}



.sesion {
    float: right;
    margin-top: -8%;
    margin-right: 2%;
    margin-bottom: -5%;
}
.user{
    margin-top: -3.2%;
    margin-right: 3%;

}


.menu{
    float: right;
    margin-top: -3.3%;
    margin-bottom: 2%;
    margin-right: 4%;
}

@media only screen and (min-width: 1366px) and (max-width: 768px) {
    
    .menu_lateral{
      background-color: antiquewhite;
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 600px) {
    

  }

