
.tarjeta{
    flex-Direction: column;
    margin-bottom: 7%;
    background-size: 100%;
    background-image: url(/static/media/tarjeta_movil.f3181c2b.png);
    background-Position-X: center;
    background-Repeat: no-repeat;
    width: 100%;
    border-Color: #0D606E;

}
.numero_tarjeta{
    margin-top: 37%;
}

.header{
    background-Color: #0D606E ;
    align-Self:center;
    width:100%;
}

.altura{
    width:100%;
    height:900;
    

}

.separar_campos{
    margin-top: 18px;
    margin-left: 70px;
    margin-bottom: 5%;
}
.digitos_tarjeta{
    margin-left: -4%;
}

.nombre{
    margin-top: -8%;
    margin-left: -5%;
}

.documento{
    margin-top: -9%;
}

.valido{
    color: white;
    margin-top: -13px;
    margin-bottom: 9%;

}
.letra{
    margin-top: -11%;
    margin-bottom: 16%;
}

.mm_aa{
    margin-left: 100px;
    margin-top: -16% !important;
    margin-bottom: 10%;
}
.csv{
    margin-top: -13%;
    margin-bottom: 14%;
}


@media only screen and (min-width: 360px) and (max-width: 600px) {
   
    .documento {
        margin-top: -10%;
        margin-left: -9%;
        max-height: 55%;
    }

    .valido {
        margin-left: -17% !important;
    }
    .mm_aa {
        margin-left: 27%;
        
    }
    
    .separar_campos {
        margin-bottom: 5%;
    }
     
    .tarjeta {
        background-size: 103%;
    width: 107%;
    height: 21%;
    margin-left: -3%;
    }
    .letra {
        font-size: 76%;
    }

    .header{
      
    }
    .digitos_tarjeta {
        margin-left: -18%;
    margin-right: -4%;
    }
    .csv {
        margin-top: -18%;
        max-height: 99%;
    
    }
       
    .numero_tarjeta {
        margin-top: 34%;
        border-color: #ffffffbd;
    }

    .nombre {
        max-height: 95%;
    margin-top: -11%;
    margin-left: -14%;
    }


}


/* Dispositivos extra pequeños (teléfonos, 600 px y menos) */
@media only screen and (min-width: 0px) and (max-width: 360px) {

}

/* Dispositivos pequeños (tabletas verticales y teléfonos grandes, 600 px y más) */
@media only screen and (min-width: 600px) {

}

/* Dispositivos medianos (tabletas horizontales, 768 px y más) */
@media only screen and (min-width: 768px) {

}

/* 
Dispositivos grandes (laptops/de escritorio, 992px y más) */
@media only screen and (min-width: 992px) {

}

/* Dispositivos extragrandes (portátiles y equipos de escritorio grandes, 1200 px y más) */
@media only screen and (min-width: 1200px) {

}