body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu{
    float: right;
    background: transparent;
    border: none;
    font-size: 25px;
    margin-top: -56%;
    margin-left: -14%;
}


.menu{}

.verde_1{
    margin: auto;
    display: block;
    width: auto;
    background-color: rgb(13, 96, 110);
    border-radius: 10px;
    margin-top: 0%;
    padding-bottom: 0%;
    padding-top: 4%; 
}
.cardoso{
    float: left;
    margin-left: 48%;
    width: 90%;
    margin-top: -7.5%;
}


.cerrar{
    
    float: right;
    margin-top: 4%;
    margin-right: 1%;
 
}
.text-info {
    --bs-text-opacity: 1;
    color: white 1!important;
}



.sesion {
    float: right;
    margin-top: -8%;
    margin-right: 2%;
    margin-bottom: -5%;
}
.user{
    margin-top: -3.2%;
    margin-right: 3%;

}


.menu{
    float: right;
    margin-top: -3.3%;
    margin-bottom: 2%;
    margin-right: 4%;
}

@media only screen and (min-width: 1366px) and (max-width: 768px) {
    
    .menu_lateral{
      background-color: antiquewhite;
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 600px) {
    

  }


.menu{
    float: right;
    background: transparent;
    border: none;
    font-size: 25px;
    margin-top: -56%;
    margin-left: -14%;
}


.menu{}

.verde{
    margin: auto;
    display: block;
    width: auto;
    background-color: rgb(13, 96, 110);
    border-radius: 10px;
    margin-top: 0%;
    padding-bottom: 0%;
    padding-top: 4%; 
}
.cardoso{
    float: left;
    margin-left: 48%;
    width: 90%;
    margin-top: -7.5%;
}


.cerrar{
    
    float: right;
    margin-top: 4%;
    margin-right: 1%;
 
}
.text-info {
    --bs-text-opacity: 1;
    color: white 1!important;
}



.sesion {
    float: right;
    margin-top: -8%;
    margin-right: 2%;
    margin-bottom: -5%;
}
.user{
    margin-top: -3.2%;
    margin-right: 3%;

}


.menu{
    float: right;
    margin-top: -3.3%;
    margin-bottom: 2%;
    margin-right: 4%;
}


  @media only screen and (max-width: 600px) {
    
.header{
    
    height: 1%!important;
}
    .menu{
        zoom: 44%;
        margin-right: 1%;
        margin-top: -4%;
    }
    

    
    .verde{
        padding-bottom: 4%;
        margin-top: -11%;
    }
    .cardoso{
        margin-left: 4%;
        width: 136%;
        margin-top: -9%;
    }
    
    
    .cerrar{
        
     
     
    }
    .text-info {
        
    }
    
    
    
    .sesion {
        margin-right: 12%;
        width: 131%;
    }
    .user{
   
    
    }
    
    
  }

@media only screen and (min-width: 360px) and (max-width: 600px) {
    
/* .header{

}

.verde{
    width: 165%;
    height: 86%;

}
.principal{
    width: 166%;
}
.imagen{
    height: 19%;
    margin-top: 110% !important;
    margin-left: -32%;

}
.sesion{
    margin-right: 37%;
    margin-top: 0%;
    width: 100%;
}
.carrusel{
    width: 103%;
    zoom: 50%;
    margin-left: 37%;
    margin-top: 9% !important;
}

.texto{

    
} */
  }


.tarjeta{
    flex-Direction: column;
    margin-bottom: 7%;
    background-size: 100%;
    background-image: url(/static/media/tarjeta_movil.f3181c2b.png);
    background-Position-X: center;
    background-Repeat: no-repeat;
    width: 100%;
    border-Color: #0D606E;

}
.numero_tarjeta{
    margin-top: 37%;
}

.header{
    background-Color: #0D606E ;
    align-Self:center;
    width:100%;
}

.altura{
    width:100%;
    height:900;
    

}

.separar_campos{
    margin-top: 18px;
    margin-left: 70px;
    margin-bottom: 5%;
}
.digitos_tarjeta{
    margin-left: -4%;
}

.nombre{
    margin-top: -8%;
    margin-left: -5%;
}

.documento{
    margin-top: -9%;
}

.valido{
    color: white;
    margin-top: -13px;
    margin-bottom: 9%;

}
.letra{
    margin-top: -11%;
    margin-bottom: 16%;
}

.mm_aa{
    margin-left: 100px;
    margin-top: -16% !important;
    margin-bottom: 10%;
}
.csv{
    margin-top: -13%;
    margin-bottom: 14%;
}


@media only screen and (min-width: 360px) and (max-width: 600px) {
   
    .documento {
        margin-top: -10%;
        margin-left: -9%;
        max-height: 55%;
    }

    .valido {
        margin-left: -17% !important;
    }
    .mm_aa {
        margin-left: 27%;
        
    }
    
    .separar_campos {
        margin-bottom: 5%;
    }
     
    .tarjeta {
        background-size: 103%;
    width: 107%;
    height: 21%;
    margin-left: -3%;
    }
    .letra {
        font-size: 76%;
    }

    .header{
      
    }
    .digitos_tarjeta {
        margin-left: -18%;
    margin-right: -4%;
    }
    .csv {
        margin-top: -18%;
        max-height: 99%;
    
    }
       
    .numero_tarjeta {
        margin-top: 34%;
        border-color: #ffffffbd;
    }

    .nombre {
        max-height: 95%;
    margin-top: -11%;
    margin-left: -14%;
    }


}


/* Dispositivos extra pequeños (teléfonos, 600 px y menos) */
@media only screen and (min-width: 0px) and (max-width: 360px) {

}

/* Dispositivos pequeños (tabletas verticales y teléfonos grandes, 600 px y más) */
@media only screen and (min-width: 600px) {

}

/* Dispositivos medianos (tabletas horizontales, 768 px y más) */
@media only screen and (min-width: 768px) {

}

/* 
Dispositivos grandes (laptops/de escritorio, 992px y más) */
@media only screen and (min-width: 992px) {

}

/* Dispositivos extragrandes (portátiles y equipos de escritorio grandes, 1200 px y más) */
@media only screen and (min-width: 1200px) {

}
.fondo{
    border-color: transparent!important;
}
@media only screen and (max-width: 600px) {
    
    
 
.modal-content{
    width: 98%!important;
    margin-top: 26%;
}

.fondo{
    margin-left: 570%!important;
    margin-top: -43%!important;
}
    
     .principal{
        width: 166%;
    }
    .imagen{
        height: 19%;
        margin-top: 110% !important;
        margin-left: -32%;
        
    
    }
    
    .carrusel{
        width: 103%;
        zoom: 50%;
        margin-left: 37%;
        margin-top: 9% !important;
    }
    
.fondo1{

    height: 0% !important;
    
}

.fondo4{
    background-image: url(/static/media/app.d958b912.png) !important;
    display: list-item;
    zoom: 100%;
    padding-top: 48%;
    background-size: 100%;
    margin-top: -24%;
}

.texto1{
    zoom: 33%;
}

.texto2{
    zoom: 39%;
    margin-left: -8%;
}
.texto3{
    zoom: 102%;
    margin-right: -13%;
}
.linea{
    margin-left: -30%;
    margin-top: 4% !important;
}

.linea2{
    width: 107%!important;
    margin-left: -9%;
    margin-top: 1%!important;
}
.sencillo{
    font-size: 199% !important;
}
.mujer{
    margin-left: 1% !important;
    width: 60%;
    margin-top: -6% !important;
}

/* .texto-app{
    margin-left: -156%;
    padding-bottom: 56%;
} */

.app{
    font-size: 133%!important;
    margin-left: -162%;
    font-weight: 700;

}

.ingresa{
    font-size: 109%!important;
    margin-left: -170%;
    margin-top: -25%!important;
}
.orientation{
    width: 254%;
}

.chulo{
    width: 21% !important;
    margin-left: -9%;
}

.chulo2{
    width: 13% !important;
    margin-left: 0%;
    margin-top: 2%!important;
}
.chulo3{
    width: 13% !important;
    margin-left: 0%;
    margin-top: 1%!important;
}

.corto1{
    margin-left: 9%;
    margin-top: -13%!important;
    font-size: 77%!important;
}

.corto2{
    margin-left: 17%;
    margin-top: -9%!important;
    font-size: 77%!important;
}
.corto3{
    
    margin-left: 17%;
    margin-top: -10%!important;
    font-size: 77%!important;
}
.linea3{
    width: 107%!important;
    margin-left: -9%;
    margin-top: 4%!important;
}

.cardoso1{
    margin-left: -5% !important;
    zoom: 40%;
    margin-top: -7%!important;

}

      }
@media only screen and (max-width: 600px) {
    .movilgo{
        width: 39%!important;
        margin-left: 30%!important;
        margin-top: 3%!important;
    }
    .icono1{
        width: 15% !important;
        margin-top: 1%;
        margin-left: -7%;
    }    
    
    .text1{
        font-size: 95%!important;
        margin-top: 6%;
    }

    .icono2{
        margin-left: -38%;
        width: 83%;
        margin-top: 3%;
    }

    .text2{
        font-size: 100%!important;
        margin-top: 3%;
        margin-left: -5% !important;
        margin-bottom: 8%;
    }

    .icono3{
        width: 96%!important;
        margin-left: -48%!important;
        margin-top: 1%!important;
    }

    .text3{
        font-size: 97%!important;
        margin-left: -38%!important;
        margin-top: 2%!important;
    }

    .icono4{
        width: 66%;
        margin-left: 40%;
    }

    .text4{
        font-size: 75%!important;
        margin-top: 2%;
        margin-left: 7%!important;
    }
    

    .icono5{
        width: 68%;
        margin-left: 268%;
        margin-top: -160% !important;
    }

    .text5{
        font-size: 75%!important;
        margin-top: -9.4%;
        margin-left: 35%!important;
    }

    .icono6{
        margin-left: 357%;
        margin-top: -88%;
        zoom: 57%;
    }

    .text6{       
        font-size: 83%!important;
        margin-top: -82%!important;
        margin-left: 34%!important;

    }


    .footer{
        width: 100%!important;
    }
      }
